import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ComboBox, ComboBoxItem, Colors, IconButton, Icon } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "combobox"
    }}>{`ComboBox`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ComboBox} mdxType="Props" />
    <h2 {...{
      "id": "comboboxprops"
    }}>{`ComboBoxProps`}</h2>
    <Props of={ComboBoxItem} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={2} __code={'<ComboBox\n  items={[\n    { label: \'수정하기\', icon: <Icon.EditOutline /> },\n    {\n      label: \'알림 끄기\',\n      description: \'해당 게시물의 메일과 푸시알림을 받지 않습니다.\',\n      icon: <Icon.BellOff />,\n    },\n    { label: \'삭제하기\', textColor: Colors.red500 },\n  ]}\n  opener={<IconButton icon={<Icon.MoreHorizontal />} />}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ComboBox,
      ComboBoxItem,
      Colors,
      IconButton,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ComboBox items={[{
        label: '수정하기',
        icon: <Icon.EditOutline />
      }, {
        label: '알림 끄기',
        description: '해당 게시물의 메일과 푸시알림을 받지 않습니다.',
        icon: <Icon.BellOff />
      }, {
        label: '삭제하기',
        textColor: Colors.red500
      }]} opener={<IconButton icon={<Icon.MoreHorizontal />} mdxType="IconButton" />} mdxType="ComboBox" />
    </Playground>
    <h2 {...{
      "id": "with-state-props"
    }}>{`With State Props`}</h2>
    <Playground __position={3} __code={'() => {\n  const [opened, setOpened] = React.useState(false)\n  const openComboBox = () => setOpened(true)\n  const closeComboBox = () => setOpened(false)\n  return (\n    <>\n      <ComboBox\n        items={[\n          { label: \'수정하기\', icon: <Icon.EditOutline /> },\n          {\n            label: \'알림 끄기\',\n            description: \'해당 게시물의 메일과 푸시알림을 받지 않습니다.\',\n            icon: <Icon.BellOff />,\n          },\n          { label: \'삭제하기\', textColor: Colors.red500 },\n        ]}\n        opened={opened}\n        onOpen={openComboBox}\n        onClose={closeComboBox}\n      >\n        <IconButton icon={<Icon.MoreHorizontal />} />\n      </ComboBox>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ComboBox,
      ComboBoxItem,
      Colors,
      IconButton,
      Icon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [opened, setOpened] = React.useState(false);

        const openComboBox = () => setOpened(true);

        const closeComboBox = () => setOpened(false);

        return <>
        <ComboBox items={[{
            label: '수정하기',
            icon: <Icon.EditOutline />
          }, {
            label: '알림 끄기',
            description: '해당 게시물의 메일과 푸시알림을 받지 않습니다.',
            icon: <Icon.BellOff />
          }, {
            label: '삭제하기',
            textColor: Colors.red500
          }]} opened={opened} onOpen={openComboBox} onClose={closeComboBox} mdxType="ComboBox">
          <IconButton icon={<Icon.MoreHorizontal />} mdxType="IconButton" />
        </ComboBox>
      </>;
      }}
    </Playground> 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      